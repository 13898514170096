import React, { useState, useEffect } from "react";
import logo from "../assets/logo-sik-bantul.png";
import LoadingOverlay from "react-loading-overlay";
import "../styles/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { useLogin } from "../hooks/useLogin";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [toggle, setToggle] = useState(false);

  const { mutate, isLoading } = useLogin(email, password);

  const onSubmit = async (evt) => {
    evt.preventDefault();
    mutate({ email, password });
  };

  useEffect(() => {
    document.title = "Login | SIMBANGGA Kencana Bantul";
  }, []);

  return (
    <>
      <LoadingOverlay active={isLoading} spinner text="Mohon Tunggu...">
        <div className="container-fluid m-0 p-0">
          <div className="row w-100 m-0">
            <div className="col-sm-6 col-xs-12 colum colum-upper color-primary">
              <div className="container-logo d-flex">
                <img src={logo} alt="logo" className="mx-auto sik-logo" />
                <h4 className="text-white lead sik-text mx-auto font-weight-bold">
                  SISTEM INFORMASI PASANGAN USIA SUBUR <br />
                  KABUPATEN BANTUL
                </h4>
              </div>
            </div>
            <div className="col-sm-6 col-xs-12 colum colum-lower p-5 color-secondary">
              <div className="container p-4 border rounded color-third upper" style={{ maxWidth: "400px" }}>
                <form onSubmit={onSubmit}>
                  <div>
                    <label htmlFor="text" className="label-form">
                      Username
                    </label>

                    <input
                      type="text"
                      className="input-form"
                      id="text"
                      placeholder="Username"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="mb-3 form-group">
                    <label htmlFor="password" className="label-form">
                      Password
                    </label>
                    <input
                      type={toggle ? "text" : "password"}
                      className="input-form"
                      id="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                      onClick={() => setToggle(!toggle)}
                      className={toggle ? "fa fa-fw fa-eye" : "fa fa-fw fa-eye-slash"}
                      style={peek}></span>
                  </div>
                  <div className="mb-3"></div>
                  <button type="submit" className="btn-login">
                    LOGIN
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
}

export default Login;

const peek = {
  float: `right`,
  marginRight: `10px`,
  marginTop: `-25px`,
  position: `relative`,
  zIndex: `2`,
};
