import React, { Component } from "react"

class Footer extends Component {
  render() {
    return (
      <footer className="main-footer">
        <small className="text-muted">
          Copyright ©2023 | Sistem Informasi PASANGAN USIA SUBUR Kecamatan
          Bantul
        </small>
        <div className="float-right d-none d-sm-inline-block">
          <small>
            <b>Version</b> 2.0.0
          </small>
        </div>
      </footer>
    )
  }
}

export default Footer
