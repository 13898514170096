import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

function Sidebar() {
  const { state } = useAuth();
  const subdistrict_name = localStorage.getItem("subdistrict_name");
  const router = useHistory();

  const urlSection = router.location.pathname.split("/")[2] ?? "";

  const changeDistrict = () => {
    localStorage.setItem("prev_subdistrict_id", localStorage.getItem("subdistrict_id"));
    localStorage.setItem("prev_subdistrict_name", localStorage.getItem("subdistrict_name"));
    localStorage.removeItem("subdistrict_id");
    localStorage.removeItem("subdistrict_name");
    router.push("/district");
  };

  return (
    <aside className="main-sidebar elevation-4" style={{ backgroundColor: "#1A4971" }}>
      <span className="brand-link">
        <span className="brand-text px-2 text-white">{state.user.districtAdmin ? "Kabupaten Bantul" : `Kecamatan ${subdistrict_name}`}</span>
      </span>
      <div className="sidebar" style={{ backgroundColor: "#B8C4CE" }}>
        <div className="user-panel mt-3">
          <span className="pl-2">Selamat datang,</span>
          <div className="p-2">
            <span className="d-block text-dark lead">{state.user?.email}</span>
            {state.user?.admin ? (
              <span className="badge badge-danger mt-2">Super Admin</span>
            ) : state.user?.localAdmin ? (
              <span className="badge badge-success mt-2">{state.user?.name}</span>
            ) : (
              <span className="badge badge-warning mt-2">{state.user?.name}</span>
            )}
          </div>
        </div>
        <hr />
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false">
            <li className={`nav-item ${urlSection === "" ? "nav-active" : ""}`}>
              <Link to="/admin" className="nav-link text-dark">
                <i className="fas fa-home nav-icon" />
                <p>Dashboard</p>
              </Link>
            </li>

            {(state.user?.localAdmin === true || state.user?.admin === true || state.user.districtAdmin) && (
              <li className={`nav-item ${urlSection === "entry" ? "nav-active" : ""}`}>
                <Link to="/admin/entry" className="nav-link text-dark">
                  <i className="fas fa-save nav-icon" />
                  <p>Entry Data</p>
                </Link>
              </li>
            )}

            <li className={`nav-item ${urlSection === "search" ? "nav-active" : ""}`}>
              <Link to="/admin/search" className="nav-link text-dark">
                <i className="fas fa-search nav-icon" />
                <p>Cari Data</p>
              </Link>
            </li>

            <li className={`nav-item ${urlSection === "manage" ? "nav-active" : ""}`}>
              <Link to="/admin/manage" className="nav-link text-dark">
                <i className="fas fa-clipboard-list nav-icon" />
                <p>Kelola Data</p>
              </Link>
            </li>

            {(state.user?.admin === true || state.user?.localAdmin === true || state.user.districtAdmin) && (
              <li className={`nav-item ${urlSection === "add-data" ? "nav-active" : ""}`}>
                <Link to="/admin/add-data" className="nav-link text-dark">
                  <i className="fa fa-database nav-icon"></i>
                  <p>Tambah Data</p>
                </Link>
              </li>
            )}

            {(state.user?.localAdmin === true || state.user?.admin || state.user.districtAdmin) && (
              <li className={`nav-item ${urlSection === "trash" ? "nav-active" : ""}`}>
                <Link to="/admin/trash" className="nav-link text-dark">
                  <i className="fas fa-trash-restore-alt nav-icon"></i>
                  <p>Recycle Bin</p>
                </Link>
              </li>
            )}

            {(state.user?.districtAdmin === true || state.user?.admin || state.user.districtAdmin) && (
              <li className={`nav-item ${urlSection === "user" ? "nav-active" : ""}`}>
                <Link to="/admin/user" className="nav-link text-dark">
                  <i className="fas fa-user nav-icon"></i>
                  <p>Kelola User</p>
                </Link>
              </li>
            )}

            {!state.user?.districtAdmin && (
              <li className="nav-item">
                <span onClick={changeDistrict} href="#" className="nav-link text-dark" style={{ cursor: "pointer" }}>
                  <i className="fas fa-exchange-alt nav-icon"></i>
                  <p>Pindah Kecamatan</p>
                </span>
              </li>
            )}

            <li className={`nav-item ${urlSection === "print" ? "nav-active" : ""}`}>
              <Link to="/admin/print" className="nav-link text-dark">
                <i className="fas fa-print nav-icon"></i>
                <p>Cetak Dokumen</p>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
}

export default Sidebar;
