/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect } from "react";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import { Navbar, Sidebar, Footer } from "../layouts/index";
// import {
//   // Dashboard,
//   // Entry,
//   // Search,
//   // Manage,
//   // Statistic,
//   // Update,
//   // View,
//   // Trash,
//   // Delete,
//   // Restore,
//   // User,
//   // Print,
//   // Force,
// } from "./admin/index";
import "../styles/dashboard.css";
// import { useFetchSubdistricts } from "../hooks/useFetchSubdistrict";
import { useAuth } from "../context/AuthContext";
import { DefaultSuspense } from "../components/Suspense";

const Dashboard = React.lazy(() => import("./admin/dashboard/Dashboard"));
const Search = React.lazy(() => import("./admin/search/Search"));
const Entry = React.lazy(() => import("./admin/entry/Entry"));
const Manage = React.lazy(() => import("./admin/manage/Manage"));
const Additional = React.lazy(() => import("./admin/tambah-data"));
const Trash = React.lazy(() => import("./admin/trash/Trash"));
const Print = React.lazy(() => import("./admin/print/Print"));
const User = React.lazy(() => import("./admin/user/User"));
const Update = React.lazy(() => import("./admin/update/Update"));
const Statistic = React.lazy(() => import("./admin/villageComponents/VillageComponents"));

function Admin() {
  const match = useRouteMatch();
  const history = useHistory();
  const subdistrict_id = localStorage.getItem("subdistrict_id") ?? localStorage.getItem("prev_subdistrict_id");
  const { state } = useAuth();
  const { dispatch } = useAuth();

  // const { data: getDesa, isLoading, isFetched } = useFetchSubdistricts(`/${subdistrict_id}`);

  useEffect(() => {
    // if (isFetched) {
    // console.log(state.user)
    if (!state.user?.districtAdmin && !state.user?.admin) {
      //eslint-disable-next-line
      dispatch?.({ type: "SET_ROLE", payload: subdistrict_id == state.user?.subdistrict_id });
    } else{
      dispatch?.({ type: "SET_ROLE", payload: false });
    }
    // }
  }, [subdistrict_id, state.user?.subdistrict_id]);

  useEffect(() => {
    if (localStorage.getItem("prev_subdistrict_id")) {
      localStorage.setItem("subdistrict_id", localStorage.getItem("prev_subdistrict_id"));
      localStorage.setItem("subdistrict_name", localStorage.getItem("prev_subdistrict_name"));

      localStorage.removeItem("prev_subdistrict_id");
      localStorage.removeItem("prev_subdistrict_name");
    }
  }, [subdistrict_id]);

  useEffect(() => {
    const lastPath = sessionStorage.getItem("lastPage");
    if (lastPath) {
      history.push(lastPath);
    }
  }, []);

  // if (isLoading) {
  //   return <DefaultSuspense />;
  // }

  return (
    <Suspense fallback={<DefaultSuspense />}>
      <div className="wrapper">
        <Navbar />
        <Sidebar />
        <Switch>
          <Route exact path={match.url} component={Dashboard} />
          <Route path={match.url + `/entry`} component={Entry} />
          <Route path={match.url + `/search`} component={Search} />
          <Route path={match.url + `/manage`} component={Manage} />
          <Route path={match.url + `/add-data`} component={Additional} />
          <Route path={match.url + `/trash`} component={Trash} />
          <Route path={match.url + `/print`} component={Print} />
          <Route path={match.url + `/user`} component={User} />
          {/* <Route path={match.url + `/view/:id`} component={View} /> */}
          <Route path={match.url + `/desa/:desa`} component={Statistic} />
          <Route path={match.url + `/update/:id`} component={Update} />
        </Switch>
      </div>
      <Footer />
    </Suspense>
  );
}

export default Admin;
