import React from "react";
import { useState } from "react";
import LoginForm from "src/components/LoginModal";
import { CardSkeleton } from "src/components/skeleton/CardSkeleton";
import { ChartSkeleton } from "src/components/skeleton/ChartSkeleton";

import { useFetchDistricts } from "src/hooks/useFetchDistrict";
import { useFetchParticipation } from "src/hooks/useFetchParticipation";
import { useFetchSubdistricts } from "src/hooks/useFetchSubdistrict";
import Footer from "src/layouts/landing/Footer";
import Navbar from "src/layouts/landing/Navbar";
import BarChart from "src/modules/chart/Bar";
import DonutChart from "src/modules/chart/Donut";

const year = new Date().getFullYear();

function Landing() {
  const [openLogin, setOpenLogin] = useState(false);
  const [subDistrict, setSubDistrict] = useState({ id: 0, name: "" });
  const isSubDistrict = subDistrict.id !== 0;

  const { data: districtData, isFetching: fetchDistrict } = useFetchDistricts("/1/relation?include=true");
  const { data: subDistrictData, isFetching: fetchSubdistrict } = useFetchSubdistricts(
    `/${subDistrict.id}/relation?include=true`,
    subDistrict.id !== 0
  );

  const param = isSubDistrict ? `subdistrict_id=${subDistrict.id}` : `district_id=${1}`;
  const { data: statData, isFetching: fetchChart } = useFetchParticipation(`/count?${year}&${param}`);

  const listKecamatan = React.useMemo(() => {
    return districtData?.data?.data?.sub_districts ?? [];
  }, [districtData]);

  const listDesa = React.useMemo(() => {
    return subDistrictData?.data?.data?.villages ?? [];
  }, [subDistrictData]);

  const barChartData = React.useMemo(() => {
    if (statData) {
      return {
        total: 0,
        series: [
          {
            name: "Total Keikutsertaan KB",
            data: Object.values(statData?.data?.count_input).map((v) => v.toString()),
          },
          {
            name: "Peserta KB Baru",
            data: Object.values(statData?.data?.count_new).map((v) => v.toString()),
          },
        ],
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Des"],
      };
    }
  }, [statData]);

  const pieChartData = React.useMemo(() => {
    if (isSubDistrict) {
      return {
        total: listDesa.reduce((acc, k) => acc + parseInt(k.total_data), 0),
        series: listDesa?.map((e) => parseInt(e.total_data)) ?? [],
        labels: listDesa?.map((e) => e.name) ?? [],
      };
    } else {
      return {
        total: listKecamatan.reduce((acc, k) => acc + parseInt(k.total_data), 0),
        series: listKecamatan?.map((e) => parseInt(e.total_data)) ?? [],
        labels: listKecamatan?.map((e) => e.name) ?? [],
      };
    }
  }, [isSubDistrict, listDesa, listKecamatan]);

  function onPressLogin() {
    setOpenLogin(true);
  }

  function onClose() {
    setOpenLogin(false);
  }

  return (
    <>
      <Navbar onPressLogin={onPressLogin} />

      <div className="container">
        <div className="text-center my-5 mx-auto" style={{ maxWidth: "612px" }}>
          <h2>Selamat Datang Di Sistem Informasi Kependudukan Dan Keluarga Kabupaten Bantul</h2>
        </div>

        <section>
          {!fetchChart && (
            <div className="row">
              <div className="col-12 col-md-6 col-xl-5 mb-3">
                <div className="bg-light p-4" style={{ borderRadius: 10 }}>
                  <div className="d-flex justify-content-between">
                    <h6 className="m-0">Jumlah Peserta KB</h6>
                    {isSubDistrict && <h6>Kec. {subDistrict.name}</h6>}
                  </div>
                  <span style={{ fontSize: 32 }}>{pieChartData.total}</span>
                  {pieChartData && <DonutChart data={pieChartData.series} labels={pieChartData.labels} />}
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-7 mb-3">
                <div className="bg-light p-4" style={{ borderRadius: 10 }}>
                  <div className="d-flex justify-content-between">
                    <h6 className="m-0">Grafik Pertambahan Peserta KB </h6>
                    {isSubDistrict && <h6>Kec. {subDistrict.name}</h6>}
                  </div>

                  {/* <span style={{ fontSize: 32 }}>581234</span> */}
                  {barChartData && <BarChart series={barChartData.series} categories={barChartData.categories} />}
                </div>
              </div>
            </div>
          )}

          {fetchChart && <ChartSkeleton />}
        </section>

        <section className="mt-5">
          <div className="d-flex justify-content-between">
            <div>
              <h4>Daftar {subDistrict.id === 0 ? "Kecamatan" : `Desa`}</h4>
              <div className="dash"></div>
            </div>
            {isSubDistrict && (
              <>
                <h6>Kecamatan {subDistrict.name}</h6>
                <button
                  onClick={() => setSubDistrict({ id: 0, name: "" })}
                  className="btn btn-light"
                  style={{ fontSize: 12, height: "32px" }}>
                  Kembali
                </button>
              </>
            )}
          </div>
          <div className="row">
            {!isSubDistrict &&
              !fetchDistrict &&
              listKecamatan?.map((data) => {
                return (
                  <div className="col-6 col-lg-4 col-xl-3 mb-3">
                    <div style={{ borderRadius: 10, overflow: "hidden" }}>
                      <div className="bg-light p-4">
                        <h6 className="m-0">{data.name}</h6>
                        <small>Jumlah Terdaftar</small>
                        <h5 className="mt-3 text-center" style={{ fontSize: "32px", fontWeight: "bold" }}>
                          {data.total_data}
                        </h5>
                      </div>
                      <button
                        onClick={() => setSubDistrict({ id: data.id, name: data.name })}
                        className="bg-info btn p-2 w-100 text-center">
                        Info Lebih Lanjut
                      </button>
                    </div>
                  </div>
                );
              })}

            {isSubDistrict &&
              !fetchSubdistrict &&
              listDesa?.map((data) => {
                return (
                  <div className="col-6 col-lg-4 col-xl-3 mb-3">
                    <div style={{ borderRadius: 10, overflow: "hidden" }}>
                      <div className="bg-light p-4">
                        <h6 className="m-0">{data.name}</h6>
                        <small>Jumlah Terdaftar</small>
                        <h5 className="mt-3 text-center" style={{ fontSize: "32px", fontWeight: "bold" }}>
                          {data.total_data}
                        </h5>
                      </div>
                      {/* <button className="bg-info btn p-2 w-100 text-center">Info Lebih Lanjut</button> */}
                    </div>
                  </div>
                );
              })}
          </div>

          {(fetchDistrict || fetchSubdistrict) && <CardSkeleton />}
        </section>
      </div>

      <LoginForm open={openLogin} onClose={onClose} />

      <Footer />
    </>
  );
}

export default Landing;
