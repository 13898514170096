import { useQuery } from "@tanstack/react-query";
import axiosInstance from "src/utils/axios";

export function useFetchDistricts(query = "", enabled) {
  return useQuery({
    enabled,
    queryKey: ["district", query],
    queryFn: () => axiosInstance.get(`/districts${query}`),
    staleTime: 5 * 60 * 1000,
  });
}
