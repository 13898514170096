import Skeleton from "react-loading-skeleton";

export function CardSkeleton() {
  return (
    <div className="row">
      {Array(8)
        .fill("*")
        .map(() => {
          return (
            <div className="col-6 col-lg-4 col-xl-3 mb-3">
              <Skeleton height={150} width={"100%"} />
            </div>
          );
        })}
    </div>
  );
}
