import React from "react";
import ReactApexChart from "react-apexcharts";
import { getChartOptions } from "src/utils/chartOptions";

function BarChart(props) {
  const series = props?.series;

  const options = getChartOptions("bar", {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: props.categories,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: false,
        columnWidth: "80%", // Adjust the width of the bars
        barHeight: "90%", // Adjust the gap between bars
        barPadding: "10%",
      },
    },
    legend: {
      position: "bottom",
    },
  });

  return (
    <div id="chart" className="w-100">
      <ReactApexChart options={options} series={series} type="bar" height={350} />
    </div>
  );
}

export default BarChart;
