import React, { useContext, useReducer, useCallback, useEffect } from "react";
import { AuthReducer, AuthState } from "../reducer/AuthReducer";
import axios from "../utils/axios";

const AuthContext = React.createContext({ state: AuthState });

export default function AuthContextProvider(props) {
  const [state, dispatch] = useReducer(AuthReducer, AuthState);

  const initialize = useCallback(async () => {
    try {
      dispatch({ type: "INITIALIZING" });
      const token = localStorage.getItem("token") ?? "";
      if (token) {
        const response = await axios.get("/profiles/login");

        if (response.status === 401) {
          localStorage.removeItem("token");
          window.location.href = "/";
        }

        if (response.status === 200) {
          dispatch({
            type: "LOGIN",
            payload: {
              isAuthenticated: true,
              user: response?.data?.data,
            },
          });
        }
      } else {
        dispatch({ type: "INITIAL" });
      }
    } catch (error) {
      dispatch({ type: "INITIAL" });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return <AuthContext.Provider value={{ state, dispatch, initialize }}>{props.children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
