import { DefaultSuspense } from "../components/Suspense";
import { useAuth } from "./AuthContext";
import { Redirect } from "react-router-dom";

export function RouteGuard(props) {
  const { state } = useAuth();

  // console.log("state", state);

  const authenticated = state.isAuthenticated;
  const isInitializing = state.isInitializing;
  const isDistrictAdmin = state.user?.districtAdmin ?? false;
  const hasDistrict = localStorage.getItem("subdistrict_id");

  if (isInitializing) {
    return <DefaultSuspense />;
  }

  if ((authenticated && hasDistrict) || isDistrictAdmin) {
    return (
      <>
        <Redirect to="/admin" />
        {props.children}
      </>
    );
  } else if (authenticated && !hasDistrict) {
    sessionStorage.removeItem("lastPage");
    return (
      <>
        <Redirect to="/district" />
        {props.children}
      </>
    );
  } else {
    sessionStorage.removeItem("lastPage");
    return (
      <>
        <Redirect to="/" />
        {props.children}
      </>
    );
  }
}
