import React from "react";

function Footer() {
  return (
    <div className="p-3" style={{ backgroundColor: "#1A4971" }}>
      <div className="container text-light">Copyright 2022 Pemerintah Kab. Bantul</div>
    </div>
  );
}

export default Footer;
