import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../utils/axios";
import { toast } from "react-toastify";

export function useLogout() {
  return useMutation({
    mutationKey: ["logout"],
    mutationFn: () => axiosInstance.get("/logout"),
    onSuccess: () => {
      toast.info("Logout Berhasil!", {
        autoClose: 1000,
        onClose: () => {
          localStorage.removeItem("token");
          localStorage.removeItem("subdistrict_id");
          localStorage.removeItem("subdistrict_name");
          sessionStorage.removeItem("lastPage");
          window.location.href = "/";
        },
      });
    },
  });
}
