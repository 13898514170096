import React from "react";
import logo from "../../assets/logo-sik-bantul.png";

function Navbar(props) {
  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-dark" style={{ backgroundColor: "#1A4971" }}>
        <div className="container">
          <div className="d-flex justify-content-between align-items-center w-100">
            <a class="navbar-brand" href="/">
              <img src={logo} alt="logo" className="mx-auto" style={{ width: 30 }} />
              <span className="ml-2" style={{ fontSize: 14 }}>
                SISTEM INFORMASI PASANGAN USIA SUBUR KAB. BANTUL
              </span>
            </a>

            <button onClick={props.onPressLogin} className="btn btn-light" style={{ fontSize: 12, height: "32px" }}>
              MASUK
            </button>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
