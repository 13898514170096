import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import axiosInstance from "src/utils/axios";

export function useFetchParticipation(query, enabled = true) {
  return useQuery({
    enabled,
    queryKey: ["participations", query],
    queryFn: () => axiosInstance.get(`/participations${query}`),

    staleTime: 5 * 60 * 1000,
  });
}
