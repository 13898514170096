import React, { useState } from "react";
import logo from "../assets/logo-sik-bantul.png";
import { Modal } from "react-bootstrap";
import { useLogin } from "src/hooks/useLogin";

function LoginForm(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [toggle, setToggle] = useState(false);

  const { mutate, isLoading } = useLogin(email, password);

  const onSubmit = async (evt) => {
    evt.preventDefault();
    mutate({ email, password });
  };

  return (
    <Modal show={props.open} centered className="bg-third" onHide={props.onClose}>
      <Modal.Body>
        <div className="d-flex align-items-center w-100">
          <img src={logo} alt="logo" style={{ width: "64px" }} />
          <p style={{ fontSize: "20px" }}>SISTEM INFORMASI KEPENDUDUKAN DAN KELUARGA KABUPATEN BANTUL</p>
        </div>
        <hr />
        <form onSubmit={onSubmit}>
          <div>
            <label htmlFor="text" className="label-form">
              Username
            </label>

            <input
              type="text"
              className="input-form"
              id="text"
              placeholder="Username"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-3 form-group">
            <label htmlFor="password" className="label-form">
              Password
            </label>
            <input
              type={toggle ? "text" : "password"}
              className="input-form"
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span
              onClick={() => setToggle(!toggle)}
              className={toggle ? "fa fa-fw fa-eye" : "fa fa-fw fa-eye-slash"}
              style={peek}></span>
          </div>
          <div className="mb-3"></div>
          <button type="submit" className="btn-login" disabled={isLoading}>
            LOGIN
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

const peek = {
  float: `right`,
  marginRight: `10px`,
  marginTop: `-25px`,
  position: `relative`,
  zIndex: `2`,
};

export default LoginForm;
