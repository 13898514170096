export const AuthState = {
  isAuthenticated: false,
  isInitializing: false,
  user: {
    email: "",
    name: "",
    photo: "",
    subdistrict_id: "",
    admin: false, // super Admin
    localAdmin: false, // admin district
    districtAdmin: false, // admin kabupaten
  },
};

export function AuthReducer(state, action) {
  switch (action.type) {
    case "LOGIN": {
      return {
        ...state,
        isAuthenticated: true,
        isInitializing: false,
        user: {
          ...action.payload.user,
          admin: action.payload.user.role === "super_admin",
          districtAdmin: action.payload.user.role === "admin_district",
        },
      };
    }
    case "INITIALIZING": {
      return {
        ...state,
        isInitializing: true,
      };
    }
    case "INITIAL": {
      return {
        ...state,
        isAuthenticated: false,
        isInitializing: false,
        user: AuthState.user,
      };
    }
    case "SET_ROLE": {
      return {
        ...state,
        user: {
          ...state.user,
          localAdmin: action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
}
