import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { useLogout } from "src/hooks/useLogout";

function Navbar() {
  const [show, setShow] = useState(false);

  const { mutate, isLoading } = useLogout();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Logout = () => {
    mutate();
    setShow(false);
  };

  return (
    <>
      <nav
        className="main-header navbar fixed-top navbar-expand navbar-dark"
        style={{ backgroundColor: "#1A4971", border: "none" }}>
        <ul className="navbar-nav">
          <li className="nav-item">
            <span className="nav-link" data-widget="pushmenu" style={{ cursor: "pointer" }}>
              <i className="fas fa-bars" />
            </span>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto">
          <div className="nav-item">
            <span
              onClick={handleShow}
              className="nav-link bg-danger rounded ml-3"
              alt="Logout"
              style={{ cursor: "pointer" }}>
              <i className="fas fa-sign-out-alt" />
            </span>
          </div>
        </ul>
      </nav>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <b>Anda ingin logout dari website SIMBANGGA Kencana Bantul?</b>
        </Modal.Header>
        <Modal.Footer>
          <Button onClick={Logout} variant="danger">
            Logout
          </Button>
        </Modal.Footer>
      </Modal>

      {isLoading && (
        <div
          style={{
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`,
            position: `fixed`,
            zIndex: 100,
            top: 0,
            right: 0,
            width: `100%`,
            height: `100vh`,
            opacity: `0.5`,
            backgroundColor: `white`,
          }}
        />
      )}
    </>
  );
}

export default Navbar;
