import { Route, Switch, useLocation } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import Login from "./pages/Login.jsx";
import Admin from "./pages/Admin.jsx";
import District from "./pages/SelectDistrict.jsx";
import AuthContextProvider from "./context/AuthContext.jsx";
import { RouteGuard } from "./context/RouteContext.jsx";
import RQProvider from "./context/QueryClient.jsx";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import Landing from "./pages/Landing.jsx";
import 'react-loading-skeleton/dist/skeleton.css'

function AppProvider() {
  return (
    <ToastProvider autoDismiss autoDismissTimeout={2000}>
      <ToastContainer />
      <RQProvider>
        <AuthContextProvider>
          <RouteGuard>
            <App />
          </RouteGuard>
        </AuthContextProvider>
      </RQProvider>
    </ToastProvider>
  );
}

function App() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/admin")) {
      sessionStorage.setItem("lastPage", location.pathname);
    }
  }, [location]);

  return (
    <Switch>
      <Route exact path="/" component={Landing} />
      <Route path="/district" component={District} />
      <Route path="/admin" component={Admin} />
      <Route path="/login" component={Login} />
    </Switch>
  );
}

export default AppProvider;
