import axios from "axios";
import { toast } from "react-toastify";
// config
import { HOST_API_URL, HOST_API_VERSION } from "../constant/api";

// ----------------------------------------------------------------------
const baseURL = `${HOST_API_URL}${HOST_API_VERSION}/`;

const axiosInstance = axios.create({ baseURL });

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  },
  (error) => Promise.reject((error.response && error.response.data) || "Something went wrong")
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        toast.warning("Sesi telah berakhir, Silahkan Re-login", {
          autoClose: 3000,
          onClose: () => {
            localStorage.removeItem("token");
            localStorage.removeItem("subdistrict_id");
            localStorage.removeItem("subdistrict_name");
            window.location.href = '/'
          },
        });
      }
    }

    return error.response;
  }
);

export default axiosInstance;
