import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../utils/axios";
import { useAuth } from "../context/AuthContext";
import { toast } from "react-toastify";

async function loginHandler({ email, password }) {
  const resp = await axiosInstance.post("/login", { email, password });

  if (resp.data.success) {
    return resp;
  } else {
    throw new Error(resp.data.message);
  }
}

export function useLogin(email, password) {
  const { initialize } = useAuth();

  return useMutation({
    mutationKey: ["login", email, password],
    mutationFn: () => loginHandler({ email, password }),
    onSuccess: (response) => {
      let token = response.data.token;
      localStorage.setItem("token", token);
      initialize();
    },
    onError: (err) => {
      console.log(err);
      toast.error(err.message ? err.message : "Terjadi kesalahan, Silahkan coba beberapa saat lagi");
    },
  });
}
