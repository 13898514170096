import React from "react";
import ReactApexChart from "react-apexcharts";
import { getChartOptions } from "src/utils/chartOptions";

function DonutChart(props) {
  const series = props?.data;

  const options = getChartOptions("pie", {
    labels: props?.labels,
    legend: {
      position: "bottom",
    },
  });

  return (
    <div id="chart" className="w-100">
      <ReactApexChart options={options} series={series} type="pie" height={350} />
    </div>
  );
}

export default DonutChart;
