import { useQuery } from "@tanstack/react-query";
import axiosInstance from "src/utils/axios";

export function useFetchSubdistricts(query = "", enabled) {
  return useQuery({
    enabled,
    queryKey: ["subdistrict", query],
    queryFn: () => axiosInstance.get(`/subdistricts${query}`),
    staleTime: 5 * 60 * 1000,
  });
}
