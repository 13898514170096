import Skeleton from "react-loading-skeleton";

export function ChartSkeleton() {
  return (
    <div className="row">
      <div className="col-12 col-md-6 col-xl-5 mb-3">
        <Skeleton height={430} width={"100%"} />
      </div>
      <div className="col-12 col-md-6 col-xl-7 mb-3">
        <Skeleton height={430} width={"100%"} />
      </div>
    </div>
  );
}
