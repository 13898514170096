import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useFetchSubdistricts } from "../hooks/useFetchSubdistrict";
import { DefaultSuspense } from "../components/Suspense";
import { ToastProvider } from "react-toast-notifications";

function SelectDistrict() {
  const router = useHistory();
  const [selected, setSelected] = useState("1");
  const [district, setDistrict] = useState("Srandakan");

  const { state } = useAuth();
  const { data, isLoading } = useFetchSubdistricts();
  const list = data?.data?.data ?? [];

  const onSubmit = (e) => {
    e.preventDefault();
    localStorage.removeItem("prev_subdistrict_id");
    localStorage.removeItem("prev_subdistrict_name");
    localStorage.setItem("subdistrict_id", selected);
    localStorage.setItem("subdistrict_name", district);
    sessionStorage.removeItem("lastPage");
    router.replace("/admin");
  };

  const handleChange = (e) => {
    e.preventDefault();

    setSelected(e.currentTarget.value);

    let value = parseInt(e.currentTarget.value);
    let index = value - 1;
    setDistrict(e.currentTarget[index].label);
  };

  useEffect(() => {
    document.title = "Pilih Kecamatan | SIMBANGGA Kencana Bantul";
  }, []);

  return isLoading ? (
    <DefaultSuspense />
  ) : (
    <>
      <div className="container-fluid m-0 p-0 d-flex align-items-center color-primary" style={{ height: `100vh` }}>
        <div className="container p-4 border rounded color-third" style={{ maxWidth: `400px`, maxHeight: `400px` }}>
          <p>
            <i class="fas fa-user-tie mr-2"></i>Anda login sebagai {state.user?.name}
          </p>
          <hr />
          <form onSubmit={onSubmit}>
            <label htmlFor="district" className="label-form">
              Pilih Kecamatan
            </label>

            <select
              className="form-control mb-3"
              id="exampleFormControlSelect1"
              onChange={handleChange}
              defaultValue={{ value: 1 }}>
              {list.map((data, index) => (
                <option key={index} value={data.id} label={data.name}>
                  {data.name}
                </option>
              ))}
            </select>
            <button type="submit" className="btn-login">
              Pilih
            </button>
          </form>
        </div>
      </div>
      <ToastProvider />
    </>
  );
}

export default SelectDistrict;
