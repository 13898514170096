const pieChartOptions = {
  chart: {
    width: 420,
    type: "pie",
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 420,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
};

const lineChartOptions = {
  chart: {
    height: 300,
    type: "line",
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  stroke: {
    curve: "smooth",
  },
  markers: {
    size: 0,
  },
  grid: {
    row: {
      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      opacity: 0.2,
    },
  },
};

const barChartOptions = {
  chart: {
    type: "bar",
    height: 300,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "80%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
};

const donutChartOptions = {
  chart: {
    type: "donut",
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
};

export function getChartOptions(type, options) {
  switch (type) {
    case "pie": {
      return { ...pieChartOptions, ...options };
    }
    case "line": {
      return { ...lineChartOptions, ...options };
    }
    case "bar": {
      return { ...barChartOptions, ...options };
    }
    case "donut": {
      return { ...donutChartOptions, ...options };
    }
    default: {
      return { options };
    }
  }
}
